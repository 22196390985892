import React, { useEffect, useState } from "react";
import { MapContainer } from "react-leaflet";
import "./PrintableRoutes.scss";
import { Box, Button, Typography } from "@mui/material";

import Logo from "../../assets/img/logo_stati_loc.png";
import RoutingOnly from "../TripPlanner/onlyRouting";
import { MAP_VIEWS } from "../../store/constants/mapViewConstants";
import ZoomedMap from "../ZoomedMap/index";
import GoogleMapsLayer from "../GoogleMapsComponent";
import DirectionsList from "../DirectionsStep";

const PrintableRoutes = () => {
  const [printRouteDetails, setPrintRouteDetails] = useState(null);

  useEffect(() => {
    if (window.localStorage) {
      const storedDetails = window.localStorage.getItem("printRouteDetails");
      if (storedDetails) {
        setPrintRouteDetails(JSON.parse(storedDetails));
      }
    }
  }, []);

  const handlePrint = () => window.print();

  // Show loading state until data is available
  if (!printRouteDetails) {
    return <p>Loading route details...</p>;
  }

  // Show message if there are no route details
  if (
    !printRouteDetails.routeDetails ||
    printRouteDetails.routeDetails.length === 0
  ) {
    return <p>No route details available.</p>;
  }

  const { routeDetails, sourceCity, destinationCity, destinations } =
    printRouteDetails;

  return (
    <Box className="backsheet">
      <Box className="frontsheet">
        <Box className="print-container">
          {/* Header Section */}
          <Box
            className="header-logo"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={Logo} alt="Logo" />
            <Typography variant="h6" sx={{ fontSize: 20 }}>
              {new Date().toDateString()}
            </Typography>
          </Box>

          {/* Route Info */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              my: 3,
            }}
          >
            <Typography sx={{ fontSize: 22, fontWeight: 700 }}>
              Route: "{sourceCity?.name}" to "{destinationCity?.name}"
            </Typography>
            <Button variant="contained" color="success" onClick={handlePrint}>
              Print Route
            </Button>
          </Box>

          {/* Distance & Time */}
          <Box sx={{ my: 3 }}>
            <Typography className="estimation">
              <span>Estimated distance:</span> {routeDetails[0]?.distance}
            </Typography>
            <Typography className="estimation" sx={{ ml: 2 }}>
              <span>Estimated time:</span> {routeDetails[0]?.duration}
            </Typography>
          </Box>

          {/* Map Display */}
          <Box>
            <MapContainer
              center={[
                routeDetails[0]?.startLocation?.lat,
                routeDetails[0]?.startLocation?.lng,
              ]}
              zoom={10}
              style={{ height: "500px", width: "100%" }}
            >
              <GoogleMapsLayer type={MAP_VIEWS.normal} />
              <RoutingOnly
                sourceCity={{
                  lat: routeDetails[0]?.startLocation?.lat,
                  lng: routeDetails[0]?.startLocation?.lng,
                }}
                destinationCity={{
                  lat: routeDetails[0]?.endLocation?.lat,
                  lng: routeDetails[0]?.endLocation?.lng,
                }}
                destinations={destinations}
              />
            </MapContainer>
          </Box>

          {/* Directions List */}
          <Box className="routesArea" sx={{ display: "flex" }}>
            <Box className="routesDetailContent">
              <DirectionsList
                source={sourceCity}
                destination={destinationCity}
                page="printableRoutes"
              />
            </Box>

            {/* Mini Maps */}
            <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
              <ZoomedMap
                destinations={destinations}
                routeDetails={routeDetails}
                focusPoint={[
                  routeDetails[0]?.startLocation?.lat,
                  routeDetails[0]?.startLocation?.lng,
                ]}
              />

              <Box sx={{ mt: 4 }}>
                <MapContainer
                  center={[
                    routeDetails[0]?.startLocation?.lat,
                    routeDetails[0]?.startLocation?.lng,
                  ]}
                  zoom={10}
                  style={{ height: "280px", width: "310px" }}
                >
                  <GoogleMapsLayer type={MAP_VIEWS.normal} />
                  <RoutingOnly
                    sourceCity={routeDetails[0]?.startLocation}
                    destinationCity={routeDetails[0]?.endLocation}
                    destinations={destinations}
                  />
                </MapContainer>
              </Box>

              {/* Destination Mini Maps */}
              {destinations?.map((des, index) => (
                <ZoomedMap
                  key={index}
                  destinations={destinations}
                  routeDetails={routeDetails}
                  focusPoint={[des?.lat, des?.lng]}
                />
              ))}

              {/* End Location Map */}
              <ZoomedMap
                destinations={destinations}
                routeDetails={routeDetails}
                focusPoint={[
                  routeDetails[0]?.endLocation?.lat,
                  routeDetails[0]?.endLocation?.lng,
                ]}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PrintableRoutes;

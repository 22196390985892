import { useEffect, useState } from "react";
import L from "leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import { useMap } from "react-leaflet";
import { MARKERS } from "../../assets/markers/markers";

const RoutingOnly = ({
  sourceCity,
  destinationCity,
  destinations,
  zoomed = true,
}) => {
  const map = useMap();
  const [routingControl, setRoutingControl] = useState(null);

  const getIcon = (index) => {
    return L.icon({
      iconUrl: MARKERS[index],
      iconSize: [60, 60],
      iconAnchor: [30, 60],
    });
  };

  useEffect(() => {
    if (!map) return;

    // Remove existing route before adding a new one
    if (routingControl) {
      map.removeControl(routingControl);
    }

    if (sourceCity?.lat !== undefined && destinationCity?.lat !== undefined) {
      const newRoutingControl = L.Routing.control({
        waypoints: [
          L.latLng(parseFloat(sourceCity.lat), parseFloat(sourceCity.lng)),
          ...(destinations?.map((destination) =>
            L.latLng(parseFloat(destination.lat), parseFloat(destination.lng))
          ) || []),
          L.latLng(
            parseFloat(destinationCity.lat),
            parseFloat(destinationCity.lng)
          ),
        ],
        createMarker: function (i, waypoint) {
          return L.marker(waypoint.latLng, {
            icon: getIcon(i),
          });
        },
        routeWhileDragging: false,
        lineOptions: {
          styles: [{ color: "#6FA1EC", weight: 4 }],
        },
        show: false,
        showAlternatives: false,
        draggableWaypoints: false,
        addWaypoints: false,
        fitSelectedRoutes: zoomed,
      }).addTo(map);

      setRoutingControl(newRoutingControl);
    }

    return () => {
      if (routingControl) {
        map.removeControl(routingControl); // Ensure routing control is removed when component unmounts
      }
    };
  }, [map, sourceCity, destinationCity, destinations]);

  return null;
};

export default RoutingOnly;

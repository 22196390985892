import React, { useState, useEffect, useRef } from "react";
import "./tripPlanner.scss";
import "leaflet/dist/leaflet.css";
import CloseIcon from "@mui/icons-material/Close";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import {
  Add,
  Close,
  DirectionsCar,
  LocationOn,
  PanoramaFishEye,
  MoreVert,
  Print,
  SwapVert,
  List,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { clearLocalStorage, DIRECTION_ICONS } from "../../lib/helper";
import DirectionsList from "../DirectionsStep";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PrintableRoutes from "../PrintableRoutes";

const MAX_DESTINATIONS = 5;
const ItemType = { DESTINATION: "destination" };

function TripPlannerComponent({
  sourceCity,
  destinationCity,
  setSourceCity,
  setDestinationCity,
  setRouteDetails,
  onClose,
  routeDetails,
  destinations,
  setDestinations,
  setBuildTrip,
  setClearTrip,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [destinationsMetadata, setDestinationsMetadata] = useState(
    JSON.parse(localStorage.getItem("DESTINATIONS_METADATA")) || []
  );
  const [selectSourcePlace, setSelectSourcePlace] = useState(
    JSON.parse(localStorage.getItem("SOURCE_CITY_META_DATA")) || null
  );
  const [selectDestinationPlace, setSelectDestinationPlace] = useState(
    JSON.parse(localStorage.getItem("DESTINATION_CITY_META_DATA")) || null
  );
  // const [isPrintableOpen, setIsPrintableOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem(
      "DESTINATIONS_METADATA",
      JSON.stringify(destinationsMetadata)
    );
  }, [destinationsMetadata]);

  useEffect(() => {
    localStorage.setItem(
      "SOURCE_CITY_META_DATA",
      JSON.stringify(selectSourcePlace)
    );
  }, [selectSourcePlace]);

  useEffect(() => {
    localStorage.setItem(
      "DESTINATION_CITY_META_DATA",
      JSON.stringify(selectDestinationPlace)
    );
  }, [selectDestinationPlace]);

  useEffect(() => {
    const updatedMetadata = destinations.map((dest) => ({
      value: { label: dest?.name, lat: dest?.lat, lng: dest?.lng },
    }));
    setDestinationsMetadata(updatedMetadata);
  }, [destinations]);

  // Update when source or destination changes
  useEffect(() => {
    if (sourceCity && !selectSourcePlace) {
      setSelectSourcePlace({
        label: sourceCity?.name,
        lat: sourceCity?.lat,
        lng: sourceCity?.lng,
      });
    }
  }, [sourceCity]);

  useEffect(() => {
    if (destinationCity && !selectDestinationPlace) {
      setSelectDestinationPlace({
        label: destinationCity?.name,
        lat: destinationCity?.lat,
        lng: destinationCity?.lng,
      });
    }
  }, [destinationCity]);

  const handleCollapse = () => setOpen(!open);

  const handleSwap = () => {
    const tempSource = selectSourcePlace;
    setSelectSourcePlace(selectDestinationPlace);
    setSelectDestinationPlace(tempSource);

    const tempCity = sourceCity;
    setSourceCity(destinationCity);
    setDestinationCity(tempCity);
  };

  const handlePlaceChange = async (place, setPlace, setCity) => {
    try {
      setPlace(place);
      const results = await geocodeByAddress(place.label);
      const latLng = await getLatLng(results[0]);
      setCity({ ...latLng, name: place.label });
    } catch (err) {
      console.error("Error handling place change:", err);
    }
  };

  const addDestination = () => {
    if (destinationsMetadata.length < MAX_DESTINATIONS) {
      setDestinationsMetadata([...destinationsMetadata, { value: null }]);
    } else {
      toast.error("You cannot add more destinations.");
    }
  };

  const handleAdditionalDestinationPlace = async (index, destinationPlace) => {
    const updatedDestinations = [...destinationsMetadata];
    updatedDestinations[index] = { value: destinationPlace };
    setDestinationsMetadata(updatedDestinations);

    const results = await geocodeByAddress(destinationPlace.label);
    const latLng = await getLatLng(results[0]);

    const newDestinations = [...destinations];
    newDestinations[index] = { ...latLng, name: destinationPlace.label };
    setDestinations(newDestinations);
  };

  const handleRemoveDestination = (index) => {
    const newDestinationsMetadata = destinationsMetadata.filter(
      (_, i) => i !== index
    );
    const newDestinations = destinations.filter((_, i) => i !== index);

    setDestinationsMetadata(newDestinationsMetadata);
    setDestinations(newDestinations);
  };

  const handleClearTrip = () => {
    setClearTrip((pre) => pre + 1);
    clearLocalStorage();
    setSelectSourcePlace(null);
    setSelectDestinationPlace(null);
    setSourceCity(null);
    setDestinationCity(null);
    setRouteDetails(null);
    setDestinations([]);
    setDestinationsMetadata([]);
  };

  // Drag-and-Drop logic
  const moveDestination = (fromIndex, toIndex) => {
    if (fromIndex === toIndex) return; // No change if the same position is selected

    // Handle swapping Start and End Points
    if (fromIndex === -1 && toIndex === destinationsMetadata.length) {
      handleSwap(); // Swap Start and End
      return;
    }

    if (fromIndex === destinationsMetadata.length && toIndex === -1) {
      handleSwap(); // Swap End and Start
      return;
    }

    // Swap Start with an Intermediate Destination
    if (fromIndex === -1) {
      const temp = destinationsMetadata[toIndex];
      destinationsMetadata[toIndex] = { value: selectSourcePlace };
      setSelectSourcePlace(temp.value);

      const tempCity = destinations[toIndex];
      destinations[toIndex] = sourceCity;
      setSourceCity(tempCity);
      setDestinationsMetadata([...destinationsMetadata]);
      return;
    }

    // Swap End with an Intermediate Destination
    if (fromIndex === destinationsMetadata.length) {
      const temp = destinationsMetadata[toIndex];
      destinationsMetadata[toIndex] = { value: selectDestinationPlace };
      setSelectDestinationPlace(temp.value);

      const tempCity = destinations[toIndex];
      destinations[toIndex] = destinationCity;
      setDestinationCity(tempCity);
      setDestinationsMetadata([...destinationsMetadata]);
      return;
    }

    // Swap Intermediate Destination with Start
    if (toIndex === -1) {
      const temp = selectSourcePlace;
      setSelectSourcePlace(destinationsMetadata[fromIndex].value);
      destinationsMetadata[fromIndex] = { value: temp };

      const tempCity = sourceCity;
      setSourceCity(destinations[fromIndex]);
      destinations[fromIndex] = tempCity;
      setDestinationsMetadata([...destinationsMetadata]);
      return;
    }

    // Swap Intermediate Destination with End
    if (toIndex === destinationsMetadata.length) {
      const temp = selectDestinationPlace;
      setSelectDestinationPlace(destinationsMetadata[fromIndex].value);
      destinationsMetadata[fromIndex] = { value: temp };

      const tempCity = destinationCity;
      setDestinationCity(destinations[fromIndex]);
      destinations[fromIndex] = tempCity;
      setDestinationsMetadata([...destinationsMetadata]);
      return;
    }

    // Swap Between Intermediate Destinations
    const updatedDestinations = [...destinationsMetadata];
    [updatedDestinations[fromIndex], updatedDestinations[toIndex]] = [
      updatedDestinations[toIndex],
      updatedDestinations[fromIndex],
    ];

    const updatedDestCoords = [...destinations];
    [updatedDestCoords[fromIndex], updatedDestCoords[toIndex]] = [
      updatedDestCoords[toIndex],
      updatedDestCoords[fromIndex],
    ];

    setDestinationsMetadata(updatedDestinations);
    setDestinations(updatedDestCoords);
  };

  const DestinationItem = ({ index, destination, isLastIndex }) => {
    const ref = useRef(null);

    const [{ isDragging }, drag] = useDrag({
      type: ItemType.DESTINATION,
      item: { type: ItemType.DESTINATION, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: ItemType.DESTINATION,
      hover(item, monitor) {
        if (!ref.current) return;
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) return; // No action if hovering over the same item

        moveDestination(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    drag(drop(ref));

    const getIcon = () => {
      if (index === -1)
        return (
          <PanoramaFishEye
            sx={{ fontSize: "1.1rem", color: "rgb(105, 105, 105)" }}
          />
        );
      if (index === isLastIndex)
        return <LocationOn sx={{ color: "#F85B50" }} />;
      return <MoreVert sx={{ fontSize: "1.1rem" }} />;
    };

    const getPlaceholder = () => {
      if (index === -1) return "Start point";
      if (index === isLastIndex) return "End point";
      return "Intermediate destination";
    };

    const handleChange = (place) => {
      if (index === -1)
        handlePlaceChange(place, setSelectSourcePlace, setSourceCity);
      else if (index === isLastIndex)
        handlePlaceChange(place, setSelectDestinationPlace, setDestinationCity);
      else handleAdditionalDestinationPlace(index, place);
    };

    return (
      <Box
        ref={ref}
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: 1,
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
        }}
      >
        {getIcon()}
        <Box sx={{ width: "100%" }}>
          <GooglePlacesAutocomplete
            apiKey="YOUR_GOOGLE_API_KEY"
            selectProps={{
              value:
                index === -1
                  ? selectSourcePlace
                  : index === isLastIndex
                  ? selectDestinationPlace
                  : destination?.value,
              onChange: handleChange,
              placeholder: getPlaceholder(),
              className: "google-autocomplete",
            }}
          />
        </Box>
        {index !== -1 && index !== isLastIndex && (
          <IconButton
            color="inherit"
            onClick={() => handleRemoveDestination(index)}
          >
            <Close fontSize="small" />
          </IconButton>
        )}
      </Box>
    );
  };

  const handleBuildTripClick = () => {
    if (!sourceCity || !destinationCity) {
      toast.error("Please enter both the start and end points.");
      return;
    }
    setBuildTrip((prev) => prev + 1); // Trigger routing by toggling the flag
  };

  return (
    <Box className="wrapper">
      <Box className="search-container">
        <Box sx={{ display: "flex", alignItems: "start", color: "black" }}>
          <Box sx={{ width: "100%" }}>
            <DestinationItem
              index={-1}
              destination={null}
              isLastIndex={destinationsMetadata.length}
            />
            {destinationsMetadata.map((destination, index) => (
              <DestinationItem
                key={index}
                index={index}
                destination={destination}
                isLastIndex={destinationsMetadata.length}
              />
            ))}
            <DestinationItem
              index={destinationsMetadata.length}
              destination={null}
              isLastIndex={destinationsMetadata.length}
            />
          </Box>

          <IconButton color="inherit" onClick={handleSwap}>
            <SwapVert fontSize="small" />
          </IconButton>
          <IconButton color="inherit" onClick={onClose}>
            <Close fontSize="small" />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 1,
          }}
        >
          <Button
            variant="text"
            startIcon={<Add />}
            onClick={addDestination}
            sx={{ color: "black" }}
          >
            Add destination
          </Button>
          <Button
            variant="text"
            onClick={handleBuildTripClick}
            sx={{ color: "black" }}
          >
            Build trip
          </Button>
          <Button
            variant="text"
            onClick={handleClearTrip}
            sx={{ color: "black" }}
          >
            Clear trip
          </Button>
        </Box>
      </Box>

      {routeDetails?.length > 0 && (
        <>
          <Box className="rotuesDetail">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <DirectionsCar fontSize="small" />
              <Button
                variant="text"
                startIcon={<Print />}
                // onClick={() =>
                //   window.open(
                //     `/printable-routes?sourceName=${
                //       sourceCity?.name
                //     }&destinationName=${
                //       destinationCity?.name
                //     }&routeDetails=${JSON.stringify(
                //       routeDetails
                //     )}&destinations=${JSON.stringify(destinations)}`,
                //     "_blank"
                //   )
                // }

                onClick={() => {
                  window.localStorage.setItem(
                    "printRouteDetails",
                    JSON.stringify({
                      sourceCity,
                      destinationCity,
                      routeDetails,
                      destinations,
                    })
                  );
                  // setIsPrintableOpen(true);
                  return navigate("/printable-routes");
                  // window.open("/printable-routes", "_blank");
                }}
                sx={{ color: "black" }}
              >
                Print
              </Button>
              <Typography className="duration">
                {routeDetails[0]?.duration || "N/A"}
              </Typography>
              &nbsp;&nbsp;
              <Typography className="distance">
                {routeDetails[0]?.distance || "N/A"}
              </Typography>
            </Box>
            <Button
              variant="text"
              startIcon={<List />}
              onClick={handleCollapse}
              sx={{ color: "black" }}
            >
              List all steps
            </Button>
          </Box>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <DirectionsList source={sourceCity} destination={destinationCity} />
          </Collapse>
        </>
      )}

      {/* <Modal open={isPrintableOpen} onClose={() => setIsPrintableOpen(false)}>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "white",
            overflow: "auto",
            padding: "20px",
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 10, right: 10 }}
            onClick={() => setIsPrintableOpen(false)}
          >
            <CloseIcon />
          </IconButton>

          <PrintableRoutes />
        </Box>
      </Modal> */}
    </Box>
  );
}

export default function TripPlanner(props) {
  return (
    <DndProvider backend={HTML5Backend}>
      <TripPlannerComponent {...props} />
    </DndProvider>
  );
}

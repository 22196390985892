import React, { useState, useEffect } from "react";
import "./App.scss";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import Header from "./components/Header";
import MapComponent from "./components/MapContainer";
import SearchOptions from "./components/SearchOption";
import StationDetails from "./components/StationDetails";
import {
  getPromotionLinks,
  getStationsImagesData,
} from "./store/actions/stationActions";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrintableRoutes from "./components/PrintableRoutes";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TripPlanner from "./components/TripPlanner";
import { MAP_VIEWS } from "./store/constants/mapViewConstants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./components/Footer";
import ThemeRegistry from "./components/ThemeRegistry";
import { clearLocalStorage } from "./lib/helper";
import { DEFAULT_FILTER, DEFAULT_LOCATION } from "./store/constants";

function App() {
  const dispatch = useDispatch();
  const [mapCenter, setMapCenter] = useState();
  const [selectedStation, setSelectedStation] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [filterValues, setFilterValues] = useState(DEFAULT_FILTER);

  const [position, setPosition] = useState(null);
  const [sourceCity, setSourceCity] = useState(
    JSON.parse(window?.localStorage?.getItem("SOURCE_CITY") || `{}`)
  );
  const [destinationCity, setDestinationCity] = useState(
    JSON.parse(window?.localStorage?.getItem("DESTINATION_CITY") || `{}`)
  );
  const [routeDetails, setRouteDetails] = useState();
  const [destinations, setDestinations] = useState(
    JSON.parse(window?.localStorage?.getItem("DESTINATIONS") || `[]`)
  );
  const [mapView, setMapView] = useState(MAP_VIEWS.normal);
  const [isTrafficViewEnabled, setIsTrafficViewEnabled] = useState(false);

  const [tripPlannerOpen, setTripPlannerOpen] = useState(false);
  const [buildTrip, setBuildTrip] = useState(0);
  const [clearTrip, setClearTrip] = useState(0);
  const [isCurrentLocation, setIsCurrentLocation] = useState(false);
  console.log("🚀 ~ App ~ isCurrentLocation:", isCurrentLocation);

  const handleGetDirectionsClick = (station) => {
    if (destinationCity || destinations?.length) {
      return toast.warn(
        "You cannot get directed instead you can add this to your trip."
      );
    }
    setDestinationCity({
      lat: station?.Latitude,
      lng: station?.Longitude,
      name: station?.StationName,
    });
    setTripPlannerOpen(true);
    // handleShowLocation();
  };

  const handleLocationSelect = (location) => {
    setMapCenter(location); // Set center but don't reset zoom
    setPosition((prevPosition) => {
      // Only update if the new position is different
      if (
        !prevPosition ||
        prevPosition?.lat !== location?.lat ||
        prevPosition?.lng !== location?.lng
      ) {
        return location;
      }
      return prevPosition;
    });
  };

  const handleStationSelect = (station) => {
    setPosition(null);
    setSelectedStation(station);
  };

  const handleOpenModal = (station) => {
    setOpenModal(true);
  };

  const handleClearTrip = () => {
    if (window?.localStorage) {
      clearLocalStorage();
    }
    setDestinationCity(null);
    setSourceCity(null);
    setRouteDetails(null);
    setDestinations([]);

    setMapCenter(null);
    setPosition(null);
  };

  const handleShowLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (destinationCity && sourceCity) {
          handleClearTrip();
        }
        setTripPlannerOpen(false);
        if (result.state === "denied") {
          toast.error("Please enable location services to use this feature.");
        } else {
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              const { latitude, longitude } = pos.coords;
              const userLocation = { lat: latitude, lng: longitude };
              setSourceCity(userLocation);
              setMapCenter(userLocation);
              setIsCurrentLocation(true);
              setPosition(userLocation);
              toast.success("Location retrieved successfully!");
            },
            () => {
              toast.error("Unable to retrieve location.");
            },
            {
              enableHighAccuracy: true,
              timeout: 10000,
              maximumAge: 0,
            }
          );
        }
      });
    } else {
      toast.error("Geolocation is not supported by your browser.");
    }
  };

  const handleTripPlanner = async () => {
    try {
      if (!tripPlannerOpen) {
        setMapCenter(null);
        setPosition(null);
      }
      setTripPlannerOpen(!tripPlannerOpen);
    } catch (err) {
      console.error("handleTripPlanner ~ err:", err);
    }
  };

  const getLocationName = async (latitude, longitude) => {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    );
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

    const data = await response.json();
    if (data?.display_name) {
      return {
        lat: latitude,
        lng: longitude,
        name: data.display_name,
      };
    } else {
      console.error(
        "Error fetching location name:",
        data.error || "No data returned"
      );
      return {};
    }
  };

  const getCurrentLocationAndShow = async () => {
    try {
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          toast.error("Geolocation is not supported by your browser.");
          reject("Geolocation not supported.");
          return;
        }

        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;

            try {
              const locationWithName = await getLocationName(
                latitude,
                longitude
              );

              if (locationWithName?.lat && locationWithName?.lng) {
                setSourceCity(locationWithName);
                setPosition(locationWithName);
                setIsCurrentLocation(true);
                resolve(locationWithName);
              } else {
                toast.error("Failed to fetch location name.");
                reject("Failed to fetch location name.");
              }
            } catch (error) {
              console.error("Error fetching location name:", error);
              toast.error("Error fetching location name.");
              reject("Error fetching location name.");
            }
          },
          (error) => {
            console.error("Error getting location:", error);
            switch (error.code) {
              case error.PERMISSION_DENIED:
                toast.error(
                  "Please enable location access in browser settings."
                );
                reject("Location permission denied.");
                break;
              case error.POSITION_UNAVAILABLE:
                // toast.error("Location unavailable. Try again later.");
                setSourceCity({
                  name: DEFAULT_LOCATION?.locationName,
                  ...DEFAULT_LOCATION.position,
                });
                setPosition(DEFAULT_LOCATION.position);

                // reject("Location unavailable.");
                break;
              case error.TIMEOUT:
                toast.error("Location request timed out. Try again.");
                reject("Location request timed out.");
                break;
              default:
                toast.error("Failed to retrieve location.");
                reject("Unknown location error.");
                break;
            }
          },
          {
            enableHighAccuracy: true, // Try to get precise location first
            timeout: 10000, // Wait up to 10 seconds
            maximumAge: 0, // Force getting a fresh location
          }
        );
      });
    } catch (err) {
      console.err("🚀 ~ getCurrentLocationAndShow ~ err:", err);
    }
  };

  const handleAddToTrip = async (station) => {
    try {
      let currentSourceCity = sourceCity; // Use a local variable for sourceCity

      // If sourceCity isn't set, try to get the current location
      if (!currentSourceCity?.name) {
        try {
          currentSourceCity = await getCurrentLocationAndShow(); // Fetch and store location in local variable
        } catch (locationError) {
          toast.error(
            "Unable to retrieve current location. Please enable location services."
          );
          return;
        }
      }

      // If destinationCity isn't set, set the station as the destination
      if (
        !destinationCity ||
        (destinationCity && Object.keys(destinationCity).length === 0)
      ) {
        setDestinationCity({
          lat: station?.Latitude,
          lng: station?.Longitude,
          name: station?.StationName,
        });

        toast.success(`${station?.StationName} set as destination!`);
        setTripPlannerOpen(true); // Open Trip Planner after setting destination
        return;
      }

      // If both source and destination are set, add station as an intermediate destination
      const alreadyAdded = destinations.some(
        (dest) =>
          dest.lat === station?.Latitude && dest.lng === station?.Longitude
      );

      if (alreadyAdded) {
        toast.warn("This station is already in your trip.");
        return;
      }

      setDestinations((prevDestinations) => [
        ...prevDestinations,
        {
          lat: station?.Latitude,
          lng: station?.Longitude,
          name: station?.StationName,
        },
      ]);

      setTripPlannerOpen(true); // Open Trip Planner if adding intermediate stops
    } catch (error) {
      console.error("handleAddToTrip ~ error:", error);
      toast.error("Failed to add station to the trip. Please try again.");
    }
  };

  useEffect(() => {
    dispatch(getStationsImagesData());
    getCurrentLocationAndShow();
  }, []);

  useEffect(() => {
    dispatch(getPromotionLinks());
  }, [dispatch]);

  useEffect(() => {
    if (destinations?.length && window.localStorage) {
      window?.localStorage?.setItem(
        "DESTINATIONS",
        JSON.stringify(destinations)
      );
    }

    if (sourceCity && window?.localStorage) {
      window?.localStorage?.setItem("SOURCE_CITY", JSON.stringify(sourceCity));
    }
    if (destinationCity && window?.localStorage) {
      window?.localStorage?.setItem(
        "DESTINATION_CITY",
        JSON.stringify(destinationCity)
      );
    }
  }, [destinations, sourceCity, destinationCity]);

  return (
    <ThemeProvider theme={ThemeRegistry}>
      {/* <DirectionsList source={sourceCity} destination={destinationCity} /> */}
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Box className="App">
                <ToastContainer />
                <Header
                  setIsCurrentLocation={setIsCurrentLocation}
                  mapCenter={mapCenter}
                  onLocationSelect={handleLocationSelect}
                  handleShowLocation={handleShowLocation}
                  setSourceCity={setSourceCity}
                  setDestinationCity={setDestinationCity}
                  setRouteDetails={setRouteDetails}
                  handleTripPlanner={handleTripPlanner}
                  setMapView={setMapView}
                  tripPlannerOpen={tripPlannerOpen}
                  isTrafficViewEnabled={isTrafficViewEnabled}
                  setIsTrafficViewEnabled={setIsTrafficViewEnabled}
                  position={position}
                  setPosition={setPosition}
                />
                <Box className="map-section">
                  <MapComponent
                    isCurrentLocation={isCurrentLocation}
                    filterValues={filterValues}
                    center={mapCenter}
                    onStationSelect={handleStationSelect}
                    onOpenModal={handleOpenModal}
                    position={position}
                    handleShowLocation={handleShowLocation}
                    sourceCity={sourceCity}
                    destinationCity={destinationCity}
                    setRouteDetails={setRouteDetails}
                    routeDetails={routeDetails}
                    setSourceCity={setSourceCity}
                    destinations={destinations}
                    mapView={mapView}
                    buildTrip={buildTrip}
                    clearTrip={clearTrip}
                    setClearTrip={setClearTrip}
                    isTrafficViewEnabled={isTrafficViewEnabled}
                  />
                  <SearchOptions
                    setPosition={setPosition}
                    setSourceCity={setSourceCity}
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    routeDetails={routeDetails}
                  />

                  {tripPlannerOpen && (
                    <TripPlanner
                      sourceCity={sourceCity}
                      destinationCity={destinationCity}
                      setDestinationCity={setDestinationCity}
                      setSourceCity={setSourceCity}
                      onClose={() => {
                        setTripPlannerOpen(false);
                      }}
                      destinations={destinations}
                      setRouteDetails={setRouteDetails}
                      routeDetails={routeDetails}
                      setDestinations={setDestinations}
                      setBuildTrip={setBuildTrip}
                      setClearTrip={setClearTrip}
                    />
                  )}
                  <StationDetails
                    station={selectedStation}
                    isOpenModal={openModal}
                    setOpenModal={setOpenModal}
                    onGetDirectionsClick={handleGetDirectionsClick}
                    handleAddToTrip={handleAddToTrip}
                  />
                </Box>
                <Footer />
                {/* <CookieDisclaimer /> */}
              </Box>
            }
          />
          <Route path="/printable-routes" element={<PrintableRoutes />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

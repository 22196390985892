import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Box, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// const images = [
//   "https://source.unsplash.com/random/800x600?mountain",
//   "https://source.unsplash.com/random/800x600?river",
//   "https://source.unsplash.com/random/800x600?desert",
//   "https://source.unsplash.com/random/800x600?forest"
// ];

const SwiperCarousel = ({images}) => {
  return (
    <Box sx={{ position: "relative", width: "80%", margin: "auto" }}>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={10}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
      >
        {images.map((img, index) => (
          <SwiperSlide key={index}>
            <Box component="img" src={img} sx={{ width: "100%", borderRadius: 2 }} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default SwiperCarousel;

import { useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.gridlayer.googlemutant";

const GoogleMapsLayer = ({ type, isTrafficViewEnabled }) => {
  const map = useMap();
  const layersRef = useRef({});

  // Helper: fade in new layer on "load", then fade out old layer
  const smoothlySwapLayers = (key, newLayer) => {
    // If we already have a layer of this type, call it oldLayer
    const oldLayer = layersRef.current[key];

    // Start new layer at opacity=0, so it’s invisible
    newLayer.setOpacity(0);
    newLayer.addTo(map);

    // Once the new layer’s tiles finish loading, do the transition
    newLayer.on("load", () => {
      // Fade in the new layer
      newLayer.setOpacity(1);

      // If an old layer existed, fade it out and remove it
      if (oldLayer && map.hasLayer(oldLayer)) {
        oldLayer.setOpacity(0);
        setTimeout(() => {
          map.removeLayer(oldLayer);
        }, 300);
      }

      // Update the ref to this new layer
      layersRef.current[key] = newLayer;
    });
  };

  // If we need to remove a layer outright (e.g. no new one):
  const smoothlyRemoveLayer = (key) => {
    const layer = layersRef.current[key];
    if (!layer || !map.hasLayer(layer)) return;

    // Fade out quickly, remove
    layer.setOpacity(0);
    setTimeout(() => {
      map.removeLayer(layer);
      layersRef.current[key] = null;
    }, 300);
  };

  useEffect(() => {
    if (!map) return;

    // --- BASE LAYER (roadmap, satellite, or hybrid) ---
    const baseType = type === "hybrid" ? "satellite" : type;

    const newBaseLayer = L.gridLayer.googleMutant({
      type: baseType, // 'roadmap', 'satellite', 'terrain', 'hybrid'
      detectRetina: true,
      maxZoom: 21,
    });

    // Swap it in smoothly
    smoothlySwapLayers("base", newBaseLayer);

    // --- LABELS LAYER (only if type === "hybrid") ---
    if (type === "hybrid") {
      const labelsLayer = L.tileLayer(
        `https://mt1.google.com/vt/lyrs=h&x={x}&y={y}&z={z}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
        {
          maxZoom: 21,
          tileSize: 256,
          detectRetina: true,
          attribution:
            '&copy; <a href="https://www.google.com/maps">Google Maps</a>',
        }
      );
      smoothlySwapLayers("labels", labelsLayer);
    } else {
      // Not in hybrid mode, remove labels if they exist
      smoothlyRemoveLayer("labels");
    }

    // --- TRAFFIC LAYER ---
    if (isTrafficViewEnabled) {
      const trafficLayerUrl =
        type === "hybrid"
          ? `https://mt1.google.com/vt/lyrs=s,h,traffic&x={x}&y={y}&z={z}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
          : `https://mt1.google.com/vt/lyrs=m,traffic&x={x}&y={y}&z={z}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;

      const trafficLayer = L.tileLayer(trafficLayerUrl, {
        maxZoom: 21,
        tileSize: 256,
        detectRetina: true,
        attribution:
          '&copy; <a href="https://www.google.com/maps">Google Maps</a>',
      });
      smoothlySwapLayers("traffic", trafficLayer);
    } else {
      smoothlyRemoveLayer("traffic");
    }

    // Cleanup: if this component unmounts, remove all layers
    return () => {
      Object.keys(layersRef.current).forEach((key) => {
        smoothlyRemoveLayer(key);
      });
    };
  }, [map, type, isTrafficViewEnabled]);

  return null;
};

export default GoogleMapsLayer;

// export default Routing;
import React, { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import "leaflet-control-geocoder";
import { toast } from "react-toastify";
import { useMap } from "react-leaflet";
import { fetchLocationName } from "../../lib/helper";
import { MARKERS } from "../../assets/markers/markers";

L.Marker.prototype.options.icon = L.icon({
  iconUrl: MARKERS[0],
  iconSize: [25, 41],
  iconAnchor: [20, 41],
});

const Routing = ({
  sourceCity,
  destinationCity,
  setRouteDetails,
  setSourceCity,
  destinations,
  buildTrip, // <-- Add buildTrip flag
  clearTrip,
}) => {
  const map = useMap();
  const routingControlRef = useRef(null); // <-- Keep track of routing control

  const getIcon = (index) => {
    return L.icon({
      iconUrl: MARKERS[index],
      iconSize: [60, 60],
      iconAnchor: [30, 60],
    });
  };

  useEffect(() => {
    if (!map || !buildTrip) return; // <-- Only trigger when buildTrip is true

    const geocoder = L.Control.Geocoder.nominatim();

    const routingControl = L.Routing.control({
      waypoints: [
        L.latLng(parseFloat(sourceCity.lat), parseFloat(sourceCity.lng)),
        ...(destinations?.map((destination) =>
          L.latLng(parseFloat(destination.lat), parseFloat(destination.lng))
        ) || []),
        L.latLng(
          parseFloat(destinationCity.lat),
          parseFloat(destinationCity.lng)
        ),
      ],
      createMarker: (i, waypoint, n) =>
        L.marker(waypoint.latLng, { icon: getIcon(i) }),
      routeWhileDragging: true,
      lineOptions: { styles: [{ color: "#6FA1EC", weight: 4 }] },
      show: false,
      showAlternatives: false,
      addWaypoints: true,
      fitSelectedRoutes: true,
      geocoder: geocoder,
      reverseWaypoints: false,
    })
      .on("routesfound", async (e) => {
        const routes = e.routes;
        if (routes.length > 0) {
          const routeInfo = [];
          for (let i = 0; i < routes.length; i++) {
            const route = routes[i];
            const locationName = await fetchLocationName(
              route?.coordinates[0]?.lat,
              route?.coordinates[0]?.lng
            );
            setSourceCity({ ...sourceCity, name: locationName });
            routeInfo.push({
              index: i + 1,
              startLocation: {
                ...route.coordinates[0],
                name: "Source Location",
              },
              endLocation: {
                ...route.coordinates[route.coordinates.length - 1],
                name: "Destination Location",
              },
              distance: (route.summary.totalDistance / 1000).toFixed(2) + " km",
              duration: (route.summary.totalTime / 60).toFixed(2) + " minutes",
              steps: route.instructions.map((instr, idx) => ({
                step: idx + 1,
                text: instr.text,
                distance: (instr.distance / 1000).toFixed(2) + " km",
              })),
            });
          }
          setRouteDetails([...routeInfo]);
        } else {
          toast.error("No route found!", { position: "top-right" });
        }
      })
      .on("routingerror", (e) => {
        toast.error("Location not found or routing error!", {
          position: "top-right",
        });
      })
      .addTo(map);

    routingControlRef.current = routingControl;

    return () => map.removeControl(routingControl);
  }, [buildTrip]); // <-- Only listen for changes to buildTrip

  // Effect to clear routing when clearRoute is true
  useEffect(() => {
    if (routingControlRef.current) {
      map.removeControl(routingControlRef.current); // Remove the route from the map
      routingControlRef.current = null; // Reset the reference
    }
  }, [clearTrip]); // Trigger clear when key changes

  return null;
};

export default Routing;

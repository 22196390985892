import React, { useEffect, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { maneuverIcons } from "../lib/maneuverIcons";

const DirectionsList = ({ source, destination, page = "home" }) => {
  const [steps, setSteps] = useState([]);
  const [distance, setDistance] = useState("");
  const [error, setError] = useState(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ["places"], // Ensure "directions" is included
  });

  useEffect(() => {
    if (isLoaded && source?.name && destination?.name) {
      calculateRoute(source?.name, destination?.name);
    }
  }, [isLoaded, source?.name, destination?.name]);

  const calculateRoute = async (origin, destination) => {
    try {
      if (!window.google || !window.google.maps) {
        throw new Error("Google Maps API is not available.");
      }

      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin,
          destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            const stepsData = result.routes[0].legs[0].steps.map((step) => ({
              instructions: step.instructions,
              maneuver: step.maneuver || "default",
              distance: step.distance ? step.distance.text : "N/A",
            }));

            setSteps(stepsData);
            setDistance(result.routes[0].legs[0].distance.text);
          } else {
            throw new Error("No route found.");
          }
        }
      );
    } catch (error) {
      console.error("Error fetching directions:", error);
      setError(error.message || "Unable to retrieve directions.");
    }
  };

  if (loadError) {
    return <p>Error loading Google Maps: {loadError.message}</p>;
  }

  return (
    <div style={styles.container}>
      <h2 style={styles.route}>
        Route: {`"${source?.name}" To "${destination?.name}"`}
      </h2>

      {error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : steps.length === 0 ? (
        <p>Loading directions...</p>
      ) : (
        <ul
          style={{
            ...styles.list,
            ...(page === "home" && { maxHeight: "250px" }),
          }}
        >
          <li style={styles.listItem}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={maneuverIcons.start} alt="Start" style={styles.icon} />
              {source?.name}
            </div>
          </li>

          {steps.map((step, index) => (
            <li key={index} style={styles.listItem}>
              <div>
                {step.maneuver !== "default" && (
                  <img
                    src={maneuverIcons[step.maneuver]}
                    alt={step.maneuver}
                    style={styles.icon}
                  />
                )}
                <span
                  dangerouslySetInnerHTML={{ __html: step.instructions }}
                  style={styles.instruction}
                />
              </div>
              <span style={styles.distance}>{step.distance}</span>
            </li>
          ))}

          <li style={styles.listItem}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={maneuverIcons.end} alt="End" style={styles.icon} />
              {destination?.name}
            </div>
          </li>
        </ul>
      )}
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    width: "100%",
    // maxWidth: "500px",
    margin: "0 auto",
  },
  route: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "10px",
  },
  list: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
    // maxHeight: "250px",
    overflowY: "auto",
    border: "1px solid #ddd",
    borderRadius: "6px",
    backgroundColor: "#f9f9f9",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
    padding: "10px",
    backgroundColor: "#fff",
    borderBottom: "1px solid #eee",
    justifyContent: "space-between",
  },
  icon: {
    width: "20px",
    marginRight: "10px",
  },
  instruction: {
    fontSize: "14px",
    color: "#444",
  },
  distance: {
    fontSize: "12px",
    color: "#666",
    marginLeft: "10px",
  },
};

export default DirectionsList;

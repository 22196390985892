export const DEFAULT_FILTER = {
  fuelType: [],
  vehicleSize: [],
  stationStatus: ["Operational"],
  hours: [],
  paymentTypes: [],
  source: [],
};

export const DEFAULT_LOCATION = {
  position: { lat: 38.8951, lng: -77.0364 },
  locationName: "Washington, D.C.",
};

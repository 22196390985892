import STN_13480 from "./13480.jpg";
import STN_13700 from "./13700.jpg";
import STN_13965 from "./13965.jpg";
import STN_16891 from "./16891.jpg";
import STN_21063 from "./21063.jpg";

export const STATION_IMAGES_SINGLES = {
  13480: STN_13480,
  13700: STN_13700,
  13965: STN_13965,
  16891: STN_16891,
  21063: STN_21063,
};

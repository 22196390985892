import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  useMap,
  ZoomControl,
} from "react-leaflet";
import L from "leaflet";
import CLPin from "../../assets/img/C_L_Pin.png";
import CPin from "../../assets/img/C_Pin.png";
import LPin from "../../assets/img/L_Pin.png";

import CLPinWithBorder from "../../assets/img/C_L_Pin_WithBorder.png";
import CPinWithBorder from "../../assets/img/C_Pin_WithBorder.png";
import LPinWithBorder from "../../assets/img/L_Pin_WithBorder.png";

import "leaflet/dist/leaflet.css";
import "./style.scss";
import { filterServerData } from "../../lib/helper";
import Routing from "../../components/TripPlanner/routing";
import STATIONS from "../../Data/stations.json";
import { MARKERS } from "../../assets/markers/markers";
import { Box, IconButton } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import ZoomControlBtn from "../ZoomControlBtn";
import GoogleMapsLayer, { TrafficLayer } from "../GoogleMapsComponent";

const CenterComponent = ({ center }) => {
  const map = useMap();

  useEffect(() => {
    if (center) {
      map.setView(center, map.getZoom());
    }
  }, [center, map]);

  return null;
};

const ZoomToLocation = ({ coordinates }) => {
  const map = useMap();

  // Set the map's view to the selected coordinates with a zoom level of 12
  map.setView([coordinates.lat, coordinates.lng], 12);

  return null;
};

const LocationMarker = ({ position }) => {
  // Set a custom marker icon (optional)
  const customIcon = new L.Icon({
    iconUrl: MARKERS[0],
    iconSize: [60, 60],
    iconAnchor: [30, 60],
  });

  return position === null ? null : (
    <Marker position={position} icon={customIcon}>
      <Tooltip>You are here</Tooltip>
    </Marker>
  );
};

const MapComponent = ({
  isCurrentLocation,
  filterValues,
  center,
  onStationSelect,
  onOpenModal,
  position,
  sourceCity,
  destinationCity,
  setRouteDetails,
  routeDetails,
  setSourceCity,
  destinations,
  mapView,
  buildTrip,
  clearTrip,
  setClearTrip,
  isTrafficViewEnabled,
}) => {
  const [stations, setStations] = useState(STATIONS);
  const [mapState, setMapState] = useState({
    center: center,
    zoom: 10, // Default zoom level
  });

  const [filterStation, setFilterStation] = useState([]);

  const mapRef = useRef(null);

  const createIcon = (iconUrl) => {
    return L.icon({
      iconUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -30],
    });
  };

  const getPin = (station) => {
    if (
      station?.StationFuelTypeCNG === "Yes" &&
      station?.StationFuelTypeLNG === "No"
    ) {
      if (mapView === "hybrid") {
        return createIcon(CPinWithBorder);
      }

      return createIcon(CPin);
    }
    if (
      station?.StationFuelTypeLNG === "Yes" &&
      station?.StationFuelTypeCNG === "No"
    ) {
      if (mapView === "hybrid") {
        return createIcon(LPinWithBorder);
      }
      return createIcon(LPin);
    }
    if (
      station?.StationFuelTypeCNG === "Yes" &&
      station?.StationFuelTypeLNG === "Yes"
    ) {
      if (mapView === "hybrid") {
        return createIcon(CLPinWithBorder);
      }
      return createIcon(CLPin);
    }
    return false;
  };

  const handleStationClick = (station) => {
    if (onStationSelect) {
      onOpenModal(true);
      onStationSelect(station);
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current;
      map.on("moveend", () => {
        setMapState({
          center: map.getCenter(),
          zoom: map.getZoom(),
        });
      });
    }
  }, []);

  useEffect(() => {
    const filterStation = filterServerData(filterValues, stations);
    setFilterStation(filterStation);
  }, [filterValues]);

  return (
    <MapContainer
      center={mapState.center} // Preserve user-defined center
      zoom={mapState.zoom} // Preserve zoom level
      zoomControl={false}
      style={{ width: "100%", height: "100%" }}
      ref={mapRef}
      zoomAnimation={true} // Enables smooth zoom
      zoomAnimationThreshold={4} // Adjust this for better performance
      fadeAnimation={true} // Ensures tiles fade in smoothly
      markerZoomAnimation={true} // Keeps markers smooth while zooming
    >
      <ZoomControl position="bottomright" sx={{ color: "#08652e" }} />
      <GoogleMapsLayer
        type={mapView}
        isTrafficViewEnabled={isTrafficViewEnabled}
      />

      <CenterComponent center={position} />

      {position?.lat && position?.lng && (
        <ZoomToLocation coordinates={position} />
      )}
      {position?.lat && position?.lng && isCurrentLocation && (
        <LocationMarker position={position} />
      )}

      {filterStation?.map((station, index) => {
        const lat = parseFloat(station?.Latitude);
        const lng = parseFloat(station?.Longitude);
        if (isNaN(lat) || isNaN(lng)) {
          console.error(
            `Invalid coordinates for station: ${station?.StationName}`
          );
          return null;
        }
        return (
          getPin(station) && (
            <Marker
              key={index}
              position={[lat, lng]}
              icon={getPin(station)}
              eventHandlers={{
                click: () => {
                  handleStationClick(station);
                },
              }}
            ></Marker>
          )
        );
      })}
      {/* <LocationMarker position={position} /> */}
      {/* <ZoomControlBtn /> */}
      <Routing
        sourceCity={sourceCity}
        destinationCity={destinationCity}
        setSourceCity={setSourceCity}
        destinations={destinations}
        setRouteDetails={setRouteDetails}
        buildTrip={buildTrip} // <-- Add this
        clearTrip={clearTrip}
      />
    </MapContainer>
  );
};

export default MapComponent;

import React, { useState } from "react";
import { useFormik } from "formik";
import {
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  FormGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import "./searchOption.scss";
import { useNavigate } from "react-router-dom";
import { DEFAULT_FILTER } from "../../store/constants";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Comdata",
  "Fuelman Fleetwide",
  "Mastercard Fleet",
  "TCH",
  "Visa Fleet",
  "Amex",
  "Discover",
  "Tcheck",
  "WEX",
  "Clean Energy Fuel Card",
  "EFS",
  "Legacy EFS",
  "PFGift Card",
  "Voyager",
  "Fleetone",
  "PFGIFT Card",
  "Mastercard",
  "Speedway",
  "Visa",
];

const SearchOptions = ({
  setFilterValues,
  routeDetails,
  filterValues,
  setPosition,
  setSourceCity,
}) => {
  const navigate = useNavigate();
  const [isFormVisible, setFormVisible] = useState(false);
  const [paymentValue, setPaymentValue] = useState([]);

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.includes("Select all")) {
      if (paymentValue.length === names.length) {
        setPaymentValue([]);
      } else {
        setPaymentValue(names);
      }
    } else {
      const newValues = value.includes("Select all")
        ? names
        : value.filter((item) => item !== "Select all");

      setPaymentValue(newValues);
    }
  };

  const formik = useFormik({
    initialValues: DEFAULT_FILTER,
    onSubmit: (values) => {
      setPosition(null);
      setSourceCity(null);
      setFilterValues({ ...values, paymentTypes: paymentValue });
    },
  });

  const handleReset = () => {
    setPaymentValue([]);
    formik.resetForm({
      values: {
        fuelType: [],
        vehicleSize: [],
        stationStatus: ["Operational"],
        hours: [],
        paymentTypes: [],
        source: [],
      },
    });

    // Also reset your state separately if needed
    setFilterValues({
      fuelType: [],
      vehicleSize: [],
      stationStatus: ["Operational"],
      hours: [],
      paymentTypes: [],
      source: [],
    });
  };

  console.log("🚀 ~ SearchOptions ~ formik:", formik?.values);

  return (
    <Box>
      <Box className="search-option-main">
        <Box className="search-option-header">
          <Typography variant="h1" className="search-option-text">
            Search options
          </Typography>
          <IconButton onClick={toggleFormVisibility} sx={{ color: "#08652e" }}>
            {isFormVisible ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        </Box>

        {isFormVisible && (
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <FormControl
              component="fieldset"
              sx={{ marginTop: "2px !important" }}
            >
              <FormLabel component="legend">Fuel type</FormLabel>
              <FormGroup>
                {["CNG", "LNG"].map((type) => (
                  <FormControlLabel
                    key={type}
                    control={
                      <Checkbox
                        checked={formik.values.fuelType.includes(type)}
                        onChange={() => {
                          if (formik.values.fuelType.includes(type)) {
                            formik.setFieldValue(
                              "fuelType",
                              formik.values.fuelType.filter(
                                (item) => item !== type
                              )
                            );
                          } else {
                            formik.setFieldValue("fuelType", [
                              ...formik.values.fuelType,
                              type,
                            ]);
                          }
                        }}
                      />
                    }
                    label={<Typography variant="body2">{type}</Typography>}
                  />
                ))}
              </FormGroup>
            </FormControl>

            <FormControl component="fieldset" sx={{ mt: 2 }}>
              <FormLabel component="legend">Fuel source</FormLabel>
              <FormGroup>
                {["Renewable (RNG)"].map((source) => (
                  <FormControlLabel
                    key={source}
                    control={
                      <Checkbox
                        checked={formik.values.source.includes(source)}
                        onChange={() => {
                          if (formik.values.source.includes(source)) {
                            formik.setFieldValue(
                              "source",
                              formik.values.source.filter(
                                (item) => item !== source
                              )
                            );
                          } else {
                            formik.setFieldValue("source", [
                              ...formik.values.source,
                              source,
                            ]);
                          }
                        }}
                      />
                    }
                    label={<Typography variant="body2">{source}</Typography>}
                  />
                ))}
              </FormGroup>
            </FormControl>

            <FormControl component="fieldset" sx={{ mt: 2 }}>
              <FormLabel component="legend">Vehicle size</FormLabel>
              <FormGroup>
                {[
                  "Vans and box trucks",
                  "Refuse and buses",
                  "Tractor-trailers",
                ].map((size) => (
                  <FormControlLabel
                    key={size}
                    control={
                      <Checkbox
                        checked={formik.values.vehicleSize.includes(size)}
                        onChange={() => {
                          if (formik.values.vehicleSize.includes(size)) {
                            formik.setFieldValue(
                              "vehicleSize",
                              formik.values.vehicleSize.filter(
                                (item) => item !== size
                              )
                            );
                          } else {
                            formik.setFieldValue("vehicleSize", [
                              ...formik.values.vehicleSize,
                              size,
                            ]);
                          }
                        }}
                      />
                    }
                    label={<Typography variant="body2">{size}</Typography>}
                  />
                ))}
              </FormGroup>
            </FormControl>

            <FormControl component="fieldset" sx={{ mt: 2 }}>
              <FormLabel component="legend">Station status</FormLabel>
              <FormGroup>
                {["Operational", "Under maintenance", "Coming soon"].map(
                  (status) => (
                    <FormControlLabel
                      key={status}
                      control={
                        <Checkbox
                          checked={
                            formik.values.stationStatus.includes(status) ||
                            filterValues.stationStatus.includes(status)
                          }
                          onChange={() => {
                            if (
                              formik.values.stationStatus.includes(status) ||
                              filterValues.stationStatus.includes(status)
                            ) {
                              if (filterValues.stationStatus.includes(status)) {
                                const tempArr = filterValues.stationStatus;

                                tempArr.splice(tempArr.indexOf(status), 1);

                                setFilterValues({
                                  ...filterValues,
                                  stationStatus: tempArr,
                                });
                              }

                              formik.setFieldValue(
                                "stationStatus",
                                formik.values.stationStatus.filter(
                                  (item) => item !== status
                                )
                              );
                            } else {
                              formik.setFieldValue("stationStatus", [
                                ...formik.values.stationStatus,
                                status,
                              ]);
                            }
                          }}
                        />
                      }
                      label={<Typography variant="body2">{status}</Typography>}
                    />
                  )
                )}
              </FormGroup>
            </FormControl>

            <FormControl component="fieldset" sx={{ mt: 2 }}>
              <FormLabel component="legend">Hours</FormLabel>
              <FormGroup>
                {["24/7"].map((hour) => (
                  <FormControlLabel
                    key={hour}
                    control={
                      <Checkbox
                        checked={formik.values.hours.includes(hour)}
                        onChange={() => {
                          if (formik.values.hours.includes(hour)) {
                            formik.setFieldValue(
                              "hours",
                              formik.values.hours.filter(
                                (item) => item !== hour
                              )
                            );
                          } else {
                            formik.setFieldValue("hours", [
                              ...formik.values.hours,
                              hour,
                            ]);
                          }
                        }}
                      />
                    }
                    label={<Typography variant="body2">{hour}</Typography>}
                  />
                ))}
              </FormGroup>
            </FormControl>

            <FormControl component="fieldset" sx={{ mt: 2 }}>
              <FormLabel className="payment_types" component="legend">
                Payment types
              </FormLabel>

              <FormControl className="payment-select" fullWidth>
                <InputLabel id="demo-multiple-checkbox-label">
                  Select all that apply
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  className="payment-selector-input"
                  multiple
                  value={
                    paymentValue.includes("Select All") ? names : paymentValue
                  }
                  onChange={handleChange}
                  input={
                    <OutlinedInput
                      label={
                        <Typography variant="body2">
                          {/* DO NOT REMOVE THIS PLACEHOLDER*/}
                          -----------------
                        </Typography>
                      }
                    />
                  }
                  renderValue={(selected) =>
                    selected.includes("Select all")
                      ? "Select all"
                      : selected.join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  <MenuItem value="Select all">
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: { lg: 18, xs: 16 },
                          color: "#08652e",
                        },
                        paddingTop: "0",
                        paddingBottom: "0",
                      }}
                      checked={paymentValue.length === names.length}
                    />
                    <ListItemText
                      primary={
                        <Typography
                          variant="body2"
                          sx={{ fontSize: { xl: 14, xs: 12 } }}
                        >
                          Select all
                        </Typography>
                      }
                    />
                  </MenuItem>
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        className="selectOptionCheckbox"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: { lg: 18, xs: 16 },
                            color: "#08652e",
                          },
                          paddingTop: "0",
                          paddingBottom: "0",
                        }}
                        checked={paymentValue.indexOf(name) > -1}
                      />
                      <ListItemText
                        primary={
                          <Typography
                            variant="body2"
                            sx={{ fontSize: { xl: 14, xs: 12 } }}
                          >
                            {name}
                            {/* {capitalizeEachWord(name)} */}
                          </Typography>
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormControl>

            <Box className="search-option-btn">
              <Button variant="contained" color="primary" type="submit">
                Apply
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                type="reset"
                onClick={handleReset}
              >
                Reset
              </Button>
            </Box>
          </form>
        )}
      </Box>
      {/* {routeDetails && routeDetails.length > 0 && (
        <Box
          className='print-routes-main'
          style={{ top: '100px' }}
          onClick={function () {
            navigate('/printable-routes');
          }}
        >
          Print Routes
        </Box>
      )} */}
    </Box>
  );
};

export default SearchOptions;

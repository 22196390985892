// import { TileLayer } from 'react-leaflet';

export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAP_BOX_ACCESS_KEY;

export const MAP_VIEWS = {
  normal: "roadmap",

  aerial: "hybrid",

  traffic: "traffic",
};

import {
  North,
  NorthEast,
  NorthWest,
  South,
  SouthEast,
  SouthWest,
  West,
  East,
} from "@mui/icons-material";

function extractValues(filters) {
  return Object.values(filters).flat();
}

export const DIRECTION_ICONS = {
  N: <North />,
  E: <East />,
  S: <South />,
  W: <West />,
  NE: <NorthEast />,
  NW: <NorthWest />,
  SE: <SouthEast />,
  SW: <SouthWest />,
};

const PAYMENT_METHODS = {
  Comdata: "ComData",
  "Fuelman Fleetwide": "Fuelman",
  "Mastercard Fleet": "Mastercard",
  TCH: "TCHK",
  "Visa Fleet": "Visa Fleet",
  Amex: "Amex",
  Discover: "Discover",
  Tcheck: "Tcheck",
  "Clean Energy Fuel Card": "CleanEnergy",
  EFS: "EFS",
  "Legacy EFS": "Legacy EFS",
  "PFGift Card": "PFGiftCard",
  "PFGIFT Card": "PFGiftCard", // normalize case
  Voyager: "Voyager",
  Fleetone: "WEXFleetone",
  Mastercard: "Mastercard",
  Speedway: "Speedway",
  Visa: "Visa",
};

const KEYS_MAP = {
  CNG: "StationFuelTypeCNG",
  LNG: "StationFuelTypeLNG",
  "Renewable (RNG)": "StationFuelTypeRDM",
  "Vans and box trucks": "VehicleTypesBoxTrucks",
  "Refuse and buses": "VehicleTypesCarsAndVans",
  "Tractor-trailers": "VehicleTypesSemiTrucks",
  Operational: "StationStatus",
  "Under maintenance": "StationStatus",
  "Coming soon": "StationStatus",
  "24/7": "HoursOpenIs24H",
  // ...PAYMENT_METHODS,
};

export const filterServerData = (filters, targetArray) => {
  // Return all stations if no filters are selected or all filters are empty
  if (!filters || Object.values(filters).every((filter) => !filter.length)) {
    return targetArray;
  }

  const checkSubFilter = (station, filterKey, filterValues) => {
    return filterValues.some((value) => {
      const mappedKey = KEYS_MAP[value];

      if (!mappedKey) {
        console.warn(`No mapping found for filter key: ${value}`);
        return false;
      }

      // Handle stationStatus separately
      if (filterKey === "stationStatus") {
        if (value === "Operational") {
          return station[mappedKey] === "Active";
        } else if (value === "Under maintenance") {
          return station[mappedKey] === "Under maintenance";
        } else if (value === "Coming soon") {
          return station[mappedKey] === "Coming Soon";
        }
      }

      // Handle vehicle size filtering logic
      if (filterKey === "vehicleSize") {
        const vehicleSizeMapping = {
          "Vans and box trucks": [
            "Vans and box trucks",
            "Refuse and buses",
            "Tractor-trailers",
          ],
          "Refuse and buses": ["Refuse and buses", "Tractor-trailers"],
          "Tractor-trailers": ["Tractor-trailers"],
        };

        const allowedVehicleSizes = vehicleSizeMapping[value] || [value];

        return allowedVehicleSizes.some(
          (size) => station[KEYS_MAP[size]] === "Yes"
        );
      }

      // Default logic for other filters
      return station[mappedKey] === "Yes";
    });
  };

  const checkSubFilterForPayments = (station, filterValues) => {
    // if (!station.PaymentTypesAccepted) return true;

    if (filterValues?.length === 0) return true;
    return filterValues.some((value) => {
      const paymentKey = PAYMENT_METHODS[value];
      if (!paymentKey) {
        console.warn(`No mapping for payment method: ${value}`);
        return false;
      }
      return station.PaymentTypesAccepted.includes(paymentKey);
    });
  };

  const filterKeys = [
    "fuelType",
    "hours",
    "paymentTypes",
    "stationStatus",
    "vehicleSize",
    "source",
  ];

  return targetArray.filter((station) =>
    filterKeys.every((key) => {
      console.log("🚀 ~ filterKeys.every ~ key:", key);
      // If stationStatus filter is empty, return all stations
      if (
        key === "stationStatus" &&
        (!filters[key] || filters[key].length === 0)
      ) {
        return true;
      }

      if (key === "paymentTypes") {
        console.log("...working");
        return checkSubFilterForPayments(station, filters.paymentTypes);
      }

      return (
        !filters[key]?.length || checkSubFilter(station, key, filters[key])
      );
    })
  );
};

export const fetchLocationName = async (lat, lng) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=10&addressdetails=1`
    );
    const data = await response.json();
    if (data && data.address) {
      return data.display_name; // Get the readable location name
    }
    return `Un-Named Location with Lat: ${lat}, Lng: ${lng}`;
  } catch (error) {
    console.error("Error fetching location name:", error);
  }
};

export const clearLocalStorage = () => {
  window?.localStorage?.removeItem("SOURCE_CITY");
  window?.localStorage?.removeItem("DESTINATION_CITY");
  window?.localStorage?.removeItem("SOURCE_CITY_META_DATA");
  window?.localStorage?.removeItem("DESTINATION_CITY_META_DATA");
  window?.localStorage?.removeItem("DESTINATIONS_METADATA");
  window?.localStorage?.removeItem("DESTINATIONS");
};

export const capitalizeEachWord = (str) => {
  return str
    ? str
        .split(" ")
        .map((word) =>
          word.includes("-")
            ? word
                .split("-")
                .map(
                  (subWord) =>
                    subWord.charAt(0).toUpperCase() +
                    subWord.slice(1).toLowerCase()
                )
                .join("-")
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ")
    : "";
};

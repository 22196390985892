import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Tooltip,
  useMediaQuery,
  Drawer,
  List,
} from "@mui/material";
import {
  Search,
  Room,
  Map,
  Menu,
  Traffic,
  Language,
  Streetview,
  TrafficTwoTone,
  NearMe,
  MapTwoTone,
  FormatListBulleted,
  ArrowForward,
  Close,
} from "@mui/icons-material";
import "./header.scss";
import Logo from "../../assets/img/clean-energy-logo.svg";
import Tagline from "../../assets/img/tagline.svg";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { MAP_VIEWS } from "../../store/constants/mapViewConstants";
import { useTheme } from "@mui/styles";
import { clearLocalStorage } from "../../lib/helper";

const Header = ({
  setIsCurrentLocation,
  mapCenter,
  onLocationSelect,
  handleShowLocation,
  setSourceCity,
  setDestinationCity,
  setRouteDetails,
  handleTripPlanner,
  setMapView,
  tripPlannerOpen,
  isTrafficViewEnabled,
  setIsTrafficViewEnabled,
  position,
  setPosition,
}) => {
  const [selectedPlace, setSelectedPlace] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [mapTileView, setMapTileView] = useState("normal");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:1202px)");

  const handlePlaceSelect = async (place) => {
    try {
      setIsCurrentLocation(false);
      setRouteDetails(null);
      setSelectedPlace(place);
      setInputValue(place.label);
      const results = await geocodeByAddress(place.label);
      const latLng = await getLatLng(results[0]);
      onLocationSelect(latLng);
    } catch (error) {
      console.error("Error geocoding address:", error);
    }
  };

  const handleSearchClick = async () => {
    if (inputValue) {
      setIsCurrentLocation(false);
      try {
        const results = await geocodeByAddress(inputValue);
        const latLng = await getLatLng(results[0]);
        onLocationSelect(latLng);
      } catch (error) {
        console.error("Error geocoding address:", error);
      }
    } else {
      console.error("No location entered");
    }
  };

  const resetState = () => {
    if (tripPlannerOpen) {
      handleTripPlanner();
    }
    if (window?.localStorage) {
      clearLocalStorage();
    }
    setRouteDetails(null);
    setSourceCity(null);
    setDestinationCity(null);
    onLocationSelect(null);
  };

  const handleTripPlannerRest = async () => {
    try {
      const sourceCity = window?.localStorage?.getItem("SOURCE_CITY");
      const destinationCity = window?.localStorage?.getItem("DESTINATION_CITY");
      const destinations = window?.localStorage?.getItem("DESTINATIONS");

      if (
        sourceCity === "{}" ||
        destinationCity === "{}" ||
        destinations === "[]"
      ) {
        resetState();
      } else {
        window?.localStorage?.removeItem("DESTINATIONS_METADATA");
      }
      handleTripPlanner();
    } catch (err) {
      console.error("handleTripPlanner ~ err:", err);
    }
  };

  const handleChangeMapView = (viewType) => {
    try {
      if (position?.lat && position?.lng) {
        setPosition(null);
      }
      if (viewType === "traffic") {
        setIsTrafficViewEnabled((prev) => !prev);
        return;
      }
      setMapTileView(viewType);
      setMapView(MAP_VIEWS[viewType]);
    } catch (err) {
      console.error("handleChangeMapView ~ err:", err);
    }
  };

  // Handle drawer toggle for mobile
  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const handleSearchSectionClick = () => {
    let currectSearchLocation = null;
    if (mapCenter) {
      currectSearchLocation = mapCenter;
    }
    resetState();
    onLocationSelect(currectSearchLocation);
  };

  const MAP_TILE_ICONS = {
    normal: <Language />,
    traffic: (
      <Traffic
        sx={{ color: isTrafficViewEnabled === true ? "#9BCA3F" : "white" }}
      />
    ),
    aerial: <Map />,
  };

  const MAP_TILE_TOOLTIP_TEXT = {
    normal: "Switch to aerial view",
    traffic: "Switch to traffic view",
    aerial: "Switch to map view",
  };

  const TOGGLE_MAP_TITLE = {
    normal: "aerial",
    aerial: "normal",
  };
  return (
    <AppBar
      position="static"
      className="header"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      {isMobile ? (
        <>
          <Box className="mobileHeader">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <img src={Logo} alt="Logo" className="logo" />
              <img src={Tagline} alt="Tagline" className="tagline" />

              <div className="grow" />
            </Box>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => toggleDrawer(true)}
              sx={{ ml: "auto" }}
            >
              <Menu sx={{ paddingRight: "10px", fontSize: "35px" }} />
            </IconButton>

            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={() => toggleDrawer(false)}
            >
              <Box
                className="drawerContainer"
                role="presentation"
                onClick={() => toggleDrawer(false)}
              >
                <Box className="drawerContent">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      width: "100%",
                    }}
                  >
                    <IconButton
                      color="inherit"
                      sx={{ padding: "0 !important" }}
                      className="findNearStation iconButton"
                      onClick={() => toggleDrawer(false)}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                  <IconButton
                    color="inherit"
                    className="findNearStation iconButton"
                    onClick={handleShowLocation}
                  >
                    <Room />
                    <Typography variant="body1" className="iconText">
                      Find a nearby station
                    </Typography>
                  </IconButton>
                  <IconButton
                    color="inherit"
                    className="iconButton"
                    onClick={handleTripPlannerRest}
                  >
                    {/* <Map /> */}
                    <Box className="tripPlannerIconWrap">
                      <NearMe />
                    </Box>
                    <Typography variant="body1" className="iconText">
                      Trip planner
                    </Typography>
                  </IconButton>
                  <div className="search" onClick={handleSearchSectionClick}>
                    <IconButton
                      className="searchBarIcon"
                      color="inherit"
                      onClick={handleSearchClick}
                    >
                      <Room sx={{ color: "#fff", fontSize: "1rem" }} />
                    </IconButton>
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyDWSTyX3tb1R56qY1wYEkQrhUgyKmNjS8E" // Replace with your Google Places API Key
                      selectProps={{
                        value: selectedPlace,
                        onChange: handlePlaceSelect, // Custom handler for selected place
                        placeholder: "Search location",
                        className: "headerLocationSearch",
                      }}
                    />
                    <IconButton
                      className="searchBarIcon"
                      color="inherit"
                      onClick={handleSearchClick}
                    >
                      <ArrowForward sx={{ color: "#fff", fontSize: "1rem" }} />
                    </IconButton>
                  </div>
                  <IconButton
                    color="inherit"
                    className="iconButton"
                    onClick={() => handleChangeMapView("traffic")}
                  >
                    {MAP_TILE_ICONS.traffic}
                    {
                      <Typography variant="body1" className="iconText">
                        {MAP_TILE_TOOLTIP_TEXT.traffic}
                      </Typography>
                    }
                  </IconButton>
                  <IconButton
                    color="inherit"
                    className="iconButton"
                    onClick={() =>
                      handleChangeMapView(
                        TOGGLE_MAP_TITLE[
                          mapTileView !== "traffic" ? mapTileView : "normal"
                        ]
                      )
                    }
                  >
                    {
                      MAP_TILE_ICONS[
                        mapTileView !== "traffic" ? mapTileView : "normal"
                      ]
                    }
                    <Typography variant="body1" className="iconText">
                      {
                        MAP_TILE_TOOLTIP_TEXT[
                          mapTileView !== "traffic" ? mapTileView : "normal"
                        ]
                      }
                    </Typography>
                  </IconButton>
                </Box>
              </Box>
            </Drawer>
          </Box>
        </>
      ) : (
        <Toolbar className="toolbar">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
          >
            {/* <Box className="header-logo"> */}
            <img src={Logo} alt="Logo" className="header-logo" />
            <img src={Tagline} alt="Tagline" className="tagline" />
            <div className="grow" />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <IconButton
              color="inherit"
              className="findNearStation iconButton"
              onClick={handleShowLocation}
            >
              <Room />
              <Typography variant="body1" className="iconText">
                Find a nearby station
              </Typography>
            </IconButton>
            <IconButton
              color="inherit"
              className="iconButton"
              onClick={handleTripPlannerRest}
            >
              {/* <Map /> */}
              <Box className="tripPlannerIconWrap">
                <NearMe />
              </Box>
              <Typography variant="body1" className="iconText">
                Trip planner
              </Typography>
            </IconButton>

            <div className="search" onClick={handleSearchSectionClick}>
              <IconButton
                className="searchBarIcon"
                color="inherit"
                onClick={handleSearchClick}
              >
                <Room />
              </IconButton>
              <GooglePlacesAutocomplete
                apiKey="AIzaSyDWSTyX3tb1R56qY1wYEkQrhUgyKmNjS8E" // Replace with your Google Places API Key
                selectProps={{
                  value: selectedPlace,
                  onChange: handlePlaceSelect, // Custom handler for selected place
                  placeholder: "Search location",
                  className: "headerLocationSearch",
                }}
              />
              <IconButton
                className="searchBarIcon"
                color="inherit"
                onClick={handleSearchClick}
              >
                <ArrowForward />
              </IconButton>
            </div>
            <Tooltip title={MAP_TILE_TOOLTIP_TEXT.traffic} arrow>
              <IconButton
                color="inherit"
                className="mapIconBtn"
                onClick={() => handleChangeMapView("traffic")}
              >
                {MAP_TILE_ICONS.traffic}
              </IconButton>
            </Tooltip>
            <Tooltip title={MAP_TILE_TOOLTIP_TEXT[mapTileView]} arrow>
              <IconButton
                color="inherit"
                className="mapIconBtn"
                onClick={
                  () =>
                    // {
                    // mapTileView !== 'traffic' &&
                    handleChangeMapView(
                      TOGGLE_MAP_TITLE[
                        mapTileView !== "traffic" ? mapTileView : "normal"
                      ]
                    )
                  // }
                  // mapTileView === 'normal' ? 'normal' : 'aerial'
                }
              >
                {
                  MAP_TILE_ICONS[
                    mapTileView !== "traffic" ? mapTileView : "normal"
                  ]
                }
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      )}
    </AppBar>
  );
};

export default Header;
